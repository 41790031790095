@import "./variables";

html {
    scroll-behavior: smooth;
    height: 100%;
  }
  
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    text-decoration: none;
    overflow-x: hidden;
    font-family: $font2;

    .animated{
      animation-duration: 2s;
    }
}






