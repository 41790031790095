@import "../variables";

.counter {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 40px 0 40px;
    z-index: -50;
  }
  
  .counter-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    margin: 7px 7px 7px 7px;
    background-color: white;
    font-size: 50px;
    color:$tntcolor;
    font-weight: bold;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
    cursor: default;
    border-top-left-radius: 40%;
    border-bottom-right-radius: 40%;
  }

  .counter-card-text{
    font-size: 16px;
    color: $black;
    font-weight: normal;
  }
  
  @media (max-width: 768px) {
    
    .counter-card {
      height: 100px;
      width: 100px;
      margin: 3px 3px 3px 3px;
      font-size: 30px;
      &-text {
        font-size: 12px;
      }
    }
  }