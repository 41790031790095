@import "../variables";

.lecturers-area{
    height: fit-content;
    background:linear-gradient(rgba(87, 87, 87, 0),rgba(0, 0, 0, 0.8)),url('../../assets/pattern4.jpg');
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 80px 0;
    z-index: 50;

    .lecturers-area-naslov{
        font-size: 64px;
        margin-bottom: 0px;
        color:$white;
    }
    .linija{
        margin-left: auto;
        margin-right: auto;
        margin-top:40px;
        margin-bottom: 40px;
        width: 150px;
        height: 1px;
        background-color: $tntcolor;
    }

    #agenda1{
        height: 44px;
    }

    #agenda2{
        height: 56px;
    }
}

@media (max-width: 768px) {
    .lecturers-area{
        padding: 30px 0 0px 0;
    }
}

