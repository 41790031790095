@import "../variables";

.about-area{
    height: fit-content;
    background:linear-gradient($tntcolor3,$tntcolor2),url('../../assets/konferencija.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 80px 0 140px 0;
}

.about-tekst{
    margin-left: 10px;
    margin-right: 10px;
    max-width: 960px;
    color:$white;
    text-align: center;
    z-index: 2;
    .naslov{
        font-size: 64px;
        margin-bottom: 0px;
    }
    .linija{
        margin-left: auto;
        margin-right: auto;
        margin-top:40px;
        margin-bottom: 40px;
        width: 150px;
        height: 1px;
        background-color: $tntcolor;
    }
    .tekst{
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .about-area{
        padding: 30px 0 60px 0;
    }

    .naslov{
        font-size: 55px !important;
    }

    .tekst{
        font-size: 21px !important;
    }
}