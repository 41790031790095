@import "../variables";

.agenda-day{
    //background-color: rgba($color: #000000, $alpha: 0.1);
    display:flex;
    flex-direction: column;
    max-width: 500px;
    text-align: center;
    align-items: center;
    justify-content: center;

    h1{
        margin-bottom: 5px;
    }

    p{
        margin-bottom: 20px;
        font-size: 18px;
    }
}