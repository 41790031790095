@import "../variables";

.toolbar{
    width:100%;
    height: 56px;
    position:fixed;
    z-index: 50;
}

.toolbar-background{
    width:100%;
    height: 56px;
    position:fixed;
    background: $black;
    opacity: 60%;
    //filter: blur(1px);
    z-index: -1;
}

.toolbar-nav{
    display:flex;
    height: 100%;
    align-items:center;
    justify-content: center;
    padding:0 1ren;
}

.toolbar-logo2{
    flex:1;
    height: 40px;
    margin: 0px 0 0 5px;
    padding-left: -10px;
    margin-left: -34px;
}

.back{
    transition: 0.5s;

    &:hover{
        filter:brightness(60%);
        transition: 0.5s;
    }
}

.spacer{
    flex:1;
}

.spacer2{
    flex:1 !important;
}




