@import "../variables";

.backdrop{
    position:fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: $black;
    opacity: 50%;
    z-index: 100;
}