@import "../variables";

.sponsors-area{
    height: fit-content;
    background:url('../../assets/pattern2.jpg');
    background-repeat: repeat;
    //display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 0 100px 0;
    z-index: 50;
    color:$black
}

.sponsors-naslov{
    font-size: 64px;
    margin-bottom: 0px;
    text-align: center;
}

.linija{
    margin-left: auto;
        margin-right: auto;
        margin-top:40px;
        margin-bottom: 40px;
        width: 150px;
        height: 1px;
        background-color: $tntcolor;
}

.sponsors-dugme{
    display:flex;
    align-items: center;
    justify-content: center;
    margin:100px auto 0 auto;
    background-color: $white;
    color:$black !important;
    text-decoration: none !important;
    font-size: 20px;
    border: none;
    width: 150px;
    height: 60px;
    border-radius: 30px;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
    transition: 0.5s;
    :focus{outline:0 !important;}

    &:hover{
        text-decoration: none !important;
        border:0 !important;
    }
}

.aa{
    &:hover{
        text-decoration: none;
    }
}

.sponsors-area button:hover,
.sponsors-area button:active{
    background-color: $tntcolor;
    text-decoration: none !important;
    transition: 1s;
}

@media (max-width: 768px) {
    .sponsors-area{
        padding: 30px 0 60px 0;
    }

    .sponsors-dugme{
        margin-top: 50px;
    }
}